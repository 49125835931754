// CustomModelsPage.tsx

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage, db } from './firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Navbar from './Navbar';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS

// Styled Components

// Page Container
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

// Content Container
const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: #f9fafb; // Tailwind Gray-50
`;

// Sidebar
const Sidebar = styled.div`
  width: 280px;
  background-color: #ffffff;
  border-right: 1px solid #e5e7eb; // Tailwind Gray-200
  padding: 24px;
  box-sizing: border-box;
  overflow-y: auto;
`;

// Sidebar Title
const SidebarTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 24px;
  font-weight: 600;
  color: #111827; // Tailwind Gray-900
`;

// Model List
const ModelList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

// Model List Item
const ModelListItem = styled.li<{ selected: boolean }>`
  margin-bottom: 12px;
  cursor: pointer;
  padding: 12px;
  border-radius: 6px;
  background-color: ${(props) => (props.selected ? '#e0f2fe' : '#f9fafb')}; // Selected: Blue-50, Default: Gray-50
  border: 1px solid ${(props) => (props.selected ? '#38bdf8' : '#e5e7eb')}; // Selected: Blue-400, Default: Gray-200
  color: #1f2937; // Tailwind Gray-800
  transition: background 0.3s, border 0.3s;

  &:hover {
    background-color: #e0f2fe; // Blue-50
    border-color: #38bdf8; // Blue-400
  }
`;

// Model Name
const ModelName = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

// Model Date
const ModelDate = styled.span`
  display: block;
  font-size: 0.875rem;
  color: #6b7280; // Gray-500
  margin-top: 4px;
`;

// Add New Model Button
const AddModelButton = styled.button`
  width: 100%;
  padding: 12px;
  margin-top: 16px;
  background-color: #2563eb; // Blue-600
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #1d4ed8; // Blue-700
  }
`;

// Main Content
const MainContent = styled.div`
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: #f9fafb; // Gray-50
`;

// Prompt Section
const PromptSection = styled.div`
  margin-bottom: 32px;
`;

// Prompt Title
const PromptTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: #111827; // Tailwind Gray-900
`;

// Prompt Textarea
const PromptTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 16px;
  border: 1px solid #d1d5db; // Gray-300
  border-radius: 8px;
  font-size: 1rem;
  resize: none;
  margin-bottom: 16px;
  background-color: #ffffff;
  color: #111827;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #2563eb; // Blue-600
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3); // Blue-600 with opacity
  }
`;

// Generate Button
const GenerateButton = styled.button`
  background-color: #10b981; // Emerald-500
  color: #ffffff;
  border: none;
  padding: 14px 28px;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s;
  margin-right: 16px;

  &:hover {
    background-color: #059669; // Emerald-600
  }

  &:disabled {
    background-color: #9ca3af; // Gray-400
    cursor: not-allowed;
  }
`;

// Image Preview Section
const ImagePreview = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

// Generated Image
const GeneratedImage = styled.img`
  width: 100%;
  max-width: 300px;
  border: 1px solid #d1d5db; // Gray-300
  border-radius: 8px;
  object-fit: cover;
`;

// Placeholder Text
const PlaceholderText = styled.p`
  font-size: 1rem;
  color: #6b7280; // Gray-500
  margin-top: 16px;
`;

// Sign-In Prompt
const SignInPrompt = styled.div`
  padding: 32px;
  text-align: center;
`;

// Sign-In Button
const SignInButton = styled.button`
  background-color: #2563eb; // Blue-600
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  transition: background 0.3s;

  &:hover {
    background-color: #1d4ed8; // Blue-700
  }

  i {
    margin-right: 8px;
  }
`;

// Loading Message
const LoadingMessage = styled.p`
  font-size: 1rem;
  color: #6b7280; // Gray-500
  text-align: center;
  margin-top: 32px;
`;

// Error Message
const ErrorMessage = styled.p`
  font-size: 1rem;
  color: #dc2626; // Red-600
  text-align: center;
  margin-top: 32px;
`;

// Types
type Model = {
    id: string;
    modelName: string;
    storagePath: string;
    timestamp: any; // Could be Firebase Timestamp or Date
    userEmail: string;
};

// Component
const CustomModelsPage: React.FC = () => {
    const [user, loadingUser, errorUser] = useAuthState(auth);
    const [models, setModels] = useState<Model[]>([]);
    const [selectedModel, setSelectedModel] = useState<Model | null>(null);
    const [prompt, setPrompt] = useState<string>('');
    const [generatedImages, setGeneratedImages] = useState<string[]>([]);
    const [isLoadingModels, setIsLoadingModels] = useState<boolean>(false);
    const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (user) {
            fetchModels();
        }
    }, [user]);

    // Function to fetch models from Firestore
    const fetchModels = async () => {
        setIsLoadingModels(true);
        setError(null);

        try {
            if (!user?.email) {
                throw new Error('User email is not available.');
            }

            const modelsCollection = collection(db, 'image_models');
            const q = query(modelsCollection, where('userEmail', '==', user.email));
            const querySnapshot = await getDocs(q);

            const userModels: Model[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                userModels.push({
                    id: doc.id,
                    modelName: data.modelName,
                    storagePath: data.storagePath,
                    timestamp: data.timestamp,
                    userEmail: data.userEmail,
                });
            });

            // Update state with fetched models
            setModels(userModels);
        } catch (error) {
            console.error('Error fetching models:', error);
            setError('Failed to load models.');
        } finally {
            setIsLoadingModels(false);
        }
    };

    // Function to format timestamp
    const formatTimestamp = (timestamp: any) => {
        if (timestamp && timestamp.toDate) {
            const date = timestamp.toDate();
            return date.toLocaleDateString();
        }
        return '';
    };

    // Function to handle image generation
    const handleGenerateImage = async () => {
        if (!user || !prompt.trim() || !selectedModel) {
            alert('You must be signed in, select a model, and provide a prompt to generate images.');
            return;
        }

        try {
            setIsLoadingImages(true);
            setGeneratedImages([]);

            // Fetch the ID token for authenticated user
            const idToken = await user.getIdToken();

            // Cloud Function endpoint
            const endpoint = 'https://us-central1-photoop-28a4c.cloudfunctions.net/call_runpod_endpoint';

            // Payload for the request
            const payload = {
                email: user.email,
                prompt,
                modelStoragePath: selectedModel.storagePath,
            };

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            // Construct the storage path using email (Note: Using UID is recommended)
            const imageRef = ref(storage, `custom_images/${user.email}/generated_image.png`);

            // Initialize variables for retry mechanism
            const maxRetries = 10;
            let attempts = 0;
            let imageUrl = '';

            // Retry loop to get the download URL
            while (attempts < maxRetries) {
                try {
                    // Try to get the download URL
                    imageUrl = await getDownloadURL(imageRef);
                    break; // If successful, exit the loop
                } catch (error: any) {
                    if (error.code === 'storage/object-not-found') {
                        // Image not yet available, wait and retry
                        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds
                        attempts++;
                    } else {
                        // Other errors
                        throw error;
                    }
                }
            }

            if (imageUrl) {
                setGeneratedImages([imageUrl]);
            } else {
                throw new Error('Failed to retrieve generated image.');
            }
        } catch (error) {
            console.error('Error generating images:', error);
            alert('Failed to generate images. Please try again.');
        } finally {
            setIsLoadingImages(false);
        }
    };

    // Function to handle Google Sign-In
    const handleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error('Error during sign-in:', error);
        }
    };

    // Navbar Navigation Items
    const navItems = [
        {
            label: 'Home',
            to: '/',
        },
    ];

    // Render loading state
    if (loadingUser || isLoadingModels) {
        return (
            <PageContainer>
                <Navbar navItems={navItems} showSignOutButton={true} />
                <LoadingMessage>Loading...</LoadingMessage>
            </PageContainer>
        );
    }

    // Render error state
    if (errorUser || error) {
        return (
            <PageContainer>
                <Navbar navItems={navItems} showSignOutButton={true} />
                <ErrorMessage>An error occurred: {errorUser?.message || error}</ErrorMessage>
            </PageContainer>
        );
    }

    // Render sign-in prompt if user is not authenticated
    if (!user) {
        return (
            <PageContainer>
                <Navbar />
                <SignInPrompt>
                    <h2>Please Sign In to Access Your Models</h2>
                    <SignInButton onClick={handleSignIn}>
                        <i className="fab fa-google"></i> Sign in with Google
                    </SignInButton>
                </SignInPrompt>
            </PageContainer>
        );
    }

    // Render main content
    return (
        <PageContainer>
            <Navbar navItems={navItems} showSignOutButton={true} />
            <ContentContainer>
                {/* Sidebar */}
                <Sidebar>
                    <SidebarTitle>Your Models</SidebarTitle>
                    {models.length > 0 ? (
                        <ModelList>
                            {models.map((model) => (
                                <ModelListItem
                                    key={model.id}
                                    selected={selectedModel?.id === model.id}
                                    onClick={() => setSelectedModel(model)}
                                >
                                    <ModelName>{model.modelName}</ModelName>
                                    <ModelDate>{formatTimestamp(model.timestamp)}</ModelDate>
                                </ModelListItem>
                            ))}
                        </ModelList>
                    ) : (
                        <PlaceholderText>You have no models. Please train a model first.</PlaceholderText>
                    )}
                    <AddModelButton onClick={() => alert('Add New Model functionality coming soon!')}>
                        + Add New Model
                    </AddModelButton>
                </Sidebar>

                {/* Main Content */}
                <MainContent>
                    <PromptSection>
                        <PromptTitle>
                            {selectedModel ? `Prompt for ${selectedModel.modelName}` : 'Select a Model'}
                        </PromptTitle>
                        <PromptTextarea
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            placeholder="Enter your prompt here..."
                        />
                        <GenerateButton
                            onClick={handleGenerateImage}
                            disabled={!selectedModel || isLoadingImages}
                        >
                            {isLoadingImages ? 'Generating...' : 'Generate Images'}
                        </GenerateButton>
                    </PromptSection>

                    {/* Image Preview */}
                    <ImagePreview>
                        {generatedImages.length > 0 ? (
                            generatedImages.map((imageUrl, index) => (
                                <GeneratedImage key={index} src={imageUrl} alt={`Generated ${index}`} />
                            ))
                        ) : isLoadingImages ? (
                            <LoadingMessage>Generating images...</LoadingMessage>
                        ) : (
                            <PlaceholderText>
                                No images generated yet. Enter a prompt and click "Generate Images".
                            </PlaceholderText>
                        )}
                    </ImagePreview>
                </MainContent>
            </ContentContainer>
        </PageContainer>
    );
};

export default CustomModelsPage;
