import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

import owenAfter from './assets/beforeAfter/owenAfter.png';
import owenBefore from './assets/beforeAfter/owenBefore.png';

import omarAfter from './assets/linkedin/omar_creative_professional.png';
import omarBefore from './assets/beforeAfter/omarBefore.png';

import justinAfter from './assets/beforeAfter/justinAfter.png';
import justinBefore from './assets/beforeAfter/justinBefore.png';

import tinaAfter from './assets/beforeAfter/tinaAfter.png';
import tinaBefore from './assets/beforeAfter/tinaBefore.png';

const beforeAfterPairs = [
  {
    before: tinaBefore,
    after: tinaAfter,
    description: "What do I look like with a buzzcut?"
  },
  {
    before: owenBefore,
    after: owenAfter,
    description: "Give me a classic polaroid look"
  },
  {
    before: omarBefore,
    after: omarAfter,
    description: "I need a new photo for Instagram"
  },
  {
    before: justinBefore,
    after: justinAfter,
    description: "Can I get a new LinkedIn pic?"
  },
  // Add more pairs as needed
];

// Keyframe animations for fade-in and fade-out
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

// Styled components
const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 16px;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%; /* Increased width to accommodate larger images and arrow */
  height: 350px; /* Increased height to fit larger images */

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

const DescriptionCaptionWrapper = styled.div`
  width: 100%;
`;

const FadeImageWrapper = styled.div<{ fadeType: 'fade-in' | 'fade-out' }>`
  display: flex;
  animation: ${({ fadeType }) =>
    fadeType === 'fade-in'
      ? css`${fadeIn} 1s ease-in-out forwards`
      : css`${fadeOut} 1s ease-in-out forwards`};
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 260px; /* Increased width by 30% */
  height: 325px; /* Increased height by 30% */
  object-fit: cover;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;

  @media (max-width: 480px) {
    width: 182px; /* Reduced width by 30% */
    height: 227.5px; /* Reduced height by 30% */
  }
`;

const CarouselContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Matches ImageWrapper's width */
`;

const Arrow = styled.span`
  font-size: 26px; /* Slightly increased font size for better proportion with larger images */
  color: #333;
  margin: 0 16px;

  @media (max-width: 480px) {
    margin: 0px;
  }
`;

const Description = styled.div`
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  color: #555;
  text-align: center;
  width: 100%; /* Takes the full width of CarouselContentWrapper */
  max-width: 600px; /* Matches ImageWrapper's width */
  padding: 12px 20px;
  border-radius: 8px;
  position: relative;
  border-left: 4px solid #0073e6;
  border-right: 4px solid #0073e6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 36px;

  &::before,
  &::after {
    content: '"';
    font-size: 24px;
    font-weight: bold;
    color: #0073e6;
  }

  &::before {
    margin-right: 4px;
  }

  &::after {
    margin-left: 4px;
  }
`;

// Duration constants
const FADE_DURATION = 1000; // 1 second
const DISPLAY_DURATION = 7000; // 7 seconds per image pair

const BeforeAndAfterCarousel: React.FC = () => {
  const [currentPairIndex, setCurrentPairIndex] = useState(0);
  const [fadeType, setFadeType] = useState<'fade-in' | 'fade-out'>('fade-in');

  useEffect(() => {
    let fadeOutTimeout: NodeJS.Timeout;
    let fadeInTimeout: NodeJS.Timeout;

    const startFadeOut = () => {
      setFadeType('fade-out');
      // After fade-out completes, update the image pair and start fade-in
      fadeOutTimeout = setTimeout(() => {
        setCurrentPairIndex((prevIndex) => (prevIndex + 1) % beforeAfterPairs.length);
        setFadeType('fade-in');
      }, FADE_DURATION);
    };

    // Set interval to start fade-out after DISPLAY_DURATION - FADE_DURATION
    const interval = setInterval(() => {
      startFadeOut();
    }, DISPLAY_DURATION);

    // Initially, set a timeout to start the first fade-out
    fadeInTimeout = setTimeout(() => {
      startFadeOut();
    }, DISPLAY_DURATION);

    // Cleanup on unmount
    return () => {
      clearInterval(interval);
      clearTimeout(fadeOutTimeout);
      clearTimeout(fadeInTimeout);
    };
  }, []);

  const { before, after, description } = beforeAfterPairs[currentPairIndex];

  return (
    <CarouselContainer>
      <CarouselContentWrapper>
        <DescriptionCaptionWrapper>
          <FadeImageWrapper key={`description-${currentPairIndex}`} fadeType={fadeType}>
            <Description>{description}</Description>
          </FadeImageWrapper>
        </DescriptionCaptionWrapper>
        <ImageWrapper>
          <FadeImageWrapper key={`before-${currentPairIndex}`} fadeType={fadeType}>
            <ImageContainer>
              <Image src={before} alt="Before" />
            </ImageContainer>
          </FadeImageWrapper>
          <FadeImageWrapper key={`arrow-${currentPairIndex}`} fadeType={fadeType}>
            <Arrow>&#x2192;</Arrow>
          </FadeImageWrapper>
          <FadeImageWrapper key={`after-${currentPairIndex}`} fadeType={fadeType}>
            <ImageContainer>
              <Image src={after} alt="After" />
            </ImageContainer>
          </FadeImageWrapper>
        </ImageWrapper>
      </CarouselContentWrapper>
    </CarouselContainer>
  );
};

export default BeforeAndAfterCarousel;
