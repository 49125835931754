// src/components/TemplateSelectionPage.tsx

import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { templateCategories, TemplateService, Template } from './templates';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Navbar from './Navbar';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// Import Firebase Auth and Hooks
import { auth } from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

// Import Icons
import { FaGift, FaTag } from 'react-icons/fa';

// Import Toast Notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Instantiate the TemplateService
const templateService = new TemplateService(templateCategories);

// =========================
// Utility Functions for Color Manipulation
// =========================

// Color Palette
const colors = {
  primary: '#2563EB', // Blue-600
  secondary: '#3B82F6', // Blue-500
  background: '#F9FAFB', // Gray-50
  surface: '#FFFFFF',
  error: '#DC2626', // Red-600
  onPrimary: '#FFFFFF',
  onBackground: '#111827', // Gray-900
};

const hexToRgb = (hex: string): string => {
  hex = hex.replace('#', '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

const darkenColor = (hex: string, percent: number): string => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  r = Math.floor((r * (100 - percent)) / 100);
  g = Math.floor((g * (100 - percent)) / 100);
  b = Math.floor((b * (100 - percent)) / 100);

  const toHex = (c: number) => c.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// =========================
// Global Styles
// =========================
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    color: #333333;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden; /* Prevent body from scrolling */
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }
`;

// =========================
// Keyframes for animations
// =========================
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const scaleUp = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

// Fullscreen container
const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

// Content container
const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto; /* Allow content to scroll within this container */
  padding: 48px 64px;
  padding-bottom: 120px; /* Adjusted space for BottomNav */
  animation: ${fadeIn} 0.8s ease-in;
  background-color: #f9fafb;

  @media (max-width: 1024px) {
    padding: 32px 48px;
  }

  @media (max-width: 768px) {
    padding: 24px 32px;
  }

  @media (max-width: 480px) {
    padding: 16px 16px;
  }
`;

// Header container to group the top elements
const HeaderContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }

  @media (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.0) 100%
  );
  color: #fff;
`;

const ProgressBarContainer = styled.div`
  width: 60%;
  background-color: #E5E7EB;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ProgressBarFiller = styled.div<{ percentage: number }>`
  height: 8px;
  width: ${(props) => props.percentage}%;
  background-color: ${colors.primary};
  transition: width 0.4s ease-in-out;
`;

// Step Indicator
const StepIndicator = styled.h5`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${colors.onBackground};
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.9px;
`;

// Title
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  color: ${colors.onBackground};

  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const DynamicText = styled.span`
  background: linear-gradient(90deg, #2563EB, #3B82F6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`;

// Animated Photo Count
const PhotoCount = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #FF8A00, #E52E71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${scaleUp} 0.5s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

// Description
const Description = styled.p`
  font-size: 1rem;
  color: #495057;
  margin-bottom: 20px;
  line-height: 1.6;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

// Category Title
const CategoryTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: ${colors.onBackground};

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

// Total Info Display
const TotalInfoDisplay = styled.div`
  font-size: 1rem;
  color: ${colors.onBackground};
  font-weight: 600;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 480px) {
    font-size: 0.95rem;
    flex-direction: column;
    gap: 8px;
  }

  span {
    display: flex;
    align-items: center;

    strong {
      margin-left: 4px;
      color: ${colors.primary};
    }
  }
`;

// Badge Component
const Badge = styled.span<{ backgroundColor: string }>`
  position: absolute;
  top: 12px;
  left: 12px;
  background-color: ${(props) => props.backgroundColor};
  color: #ffffff;
  padding: 4px 8px;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
  z-index: 3; /* Ensure badge appears above selection overlay */
`;

// Selection Overlay
const SelectionOverlay = styled.div<{ selected: boolean }>`
  display: ${(props) => (props.selected ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 116, 162, 0.7);
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
`;

// Bottom Navigation Bar
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;
  background-color: #ffffff;
  border-top: 1px solid #E5E7EB;
  z-index: 1000; /* Ensure BottomNav appears above other content */

  // @media (max-width: 768px) {
  //   padding: 12px 24px;
  // }

  @media (max-width: 480px) {
    padding: 8px 16px;
  }
`;

// Navigation Button
const NavButton = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? colors.primary : 'transparent')};
  color: ${(props) => (props.primary ? colors.onPrimary : colors.primary)};
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: ${(props) => (props.primary ? 'none' : `2px solid ${colors.primary}`)};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
    props.primary ? colors.secondary : `${colors.primary}1A`};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// =========================
// Styled Components for TemplateCategorySection
// =========================

// Wrapper for the Templates Grid and Arrows
const TemplatesGridWrapper = styled.div`
  position: relative;
`;

// Modify TemplatesGrid to handle both horizontal and vertical layouts
const TemplatesGridStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  overflow-x: ${(props) => (props.isMobile ? 'visible' : 'auto')};
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }

  & > * {
    flex: 0 0 auto;
  }

  /* For vertical layout, adjust spacing */
  ${(props) =>
    props.isMobile &&
    `
    & > * {
      margin-bottom: 16px;
    }
  `}
`;

// Arrow Buttons
const Arrow = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 8px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  /* Hide arrows on mobile */
  @media (max-width: 480px) {
    display: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const LeftArrow = styled(Arrow)`
  left: 8px;
`;

const RightArrow = styled(Arrow)`
  right: 8px;
`;

const TemplateTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 12px;
`;

// Modify TemplateCard to adjust width and flex properties
const TemplateCardStyled = styled.div<{ selected: boolean; isMobile: boolean }>`
  position: relative;
  background-color: #ffffff;
  border: 2px solid ${(props) => (props.selected ? '#0074a2' : '#e0e0e0')};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: ${(props) =>
    props.selected
      ? '0 4px 16px rgba(0, 116, 162, 0.2)'
      : '0 2px 8px rgba(0, 0, 0, 0.05)'};
  min-width: ${(props) => (props.isMobile ? '100%' : '300px')};
  max-width: ${(props) => (props.isMobile ? '100%' : '300px')};
  flex: 0 0 auto;
  margin-right: ${(props) => (props.isMobile ? '0' : '16px')};
  margin-bottom: ${(props) => (props.isMobile ? '16px' : '0')};

  &:hover {
    border-color: #0074a2;
    box-shadow: 0 6px 20px rgba(${hexToRgb('#0074a2')}, 0.2);
  }
`;

// =========================
// TemplateCategorySection Component
// =========================
interface TemplateCategorySectionProps {
  category: {
    name: string;
    templates: Template[];
  };
  selectedTemplates: string[];
  handleTemplateSelect: (templateId: string) => void;
  getCategoryNameByTemplateId: (templateId: string) => string;
}

const TemplateCategorySection: React.FC<TemplateCategorySectionProps> = ({
  category,
  selectedTemplates,
  handleTemplateSelect,
  getCategoryNameByTemplateId,
}) => {
  const gridRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      updateArrows();
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    updateArrows();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateArrows = () => {
    const grid = gridRef.current;
    if (grid && !isMobile) {
      const { scrollWidth, clientWidth } = grid;
      setShowRightArrow(scrollWidth > clientWidth);
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(false);
      setShowRightArrow(false);
    }
  };

  const handleScroll = () => {
    const grid = gridRef.current;
    if (grid && !isMobile) {
      const { scrollLeft, scrollWidth, clientWidth } = grid;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollAmount = 300; // Adjust scroll amount as needed

  const scrollLeft = () => {
    const grid = gridRef.current;
    if (grid) {
      grid.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    const grid = gridRef.current;
    if (grid) {
      grid.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <CategoryTitle>{category.name}</CategoryTitle>
      <TemplatesGridWrapper>
        {/* Left Arrow */}
        <LeftArrow onClick={scrollLeft} visible={showLeftArrow}>
          <FaChevronLeft />
        </LeftArrow>

        {/* Right Arrow */}
        <RightArrow onClick={scrollRight} visible={showRightArrow}>
          <FaChevronRight />
        </RightArrow>

        <TemplatesGridStyled ref={gridRef} onScroll={handleScroll} isMobile={isMobile}>
          {category.templates.map((template) => {
            const isSelected = selectedTemplates.includes(template.id);
            const isDiscounted = template.isDiscounted || false;
            const isFree = template.isPaid === false;

            return (
              <TemplateCardStyled
                key={template.id}
                selected={isSelected}
                onClick={() => handleTemplateSelect(template.id)}
                tabIndex={0}
                aria-selected={isSelected}
                role="button"
                aria-label={`Select ${template.title} template`}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleTemplateSelect(template.id);
                  }
                }}
                isMobile={isMobile}
              >
                {/* Selection Overlay */}
                <SelectionOverlay selected={isSelected}>
                  Selected
                </SelectionOverlay>

                {/* Badges */}
                {(isDiscounted || isFree) && (
                  <Badge backgroundColor={isFree ? '#28a745' : '#FF5722'}>
                    {isFree ? (
                      <>
                        <FaGift style={{ marginRight: '4px' }} />
                        Free
                      </>
                    ) : (
                      <>
                        <FaTag style={{ marginRight: '4px' }} />
                        25% OFF
                      </>
                    )}
                  </Badge>
                )}

                {/* Template Image */}
                <div
                  style={{
                    width: '100%',
                    height: '0',
                    paddingBottom: '100%', // Adjusted the aspect ratio to make image shorter
                    backgroundImage: `url(${template.afterImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />

                {/* Overlay Container */}
                <OverlayContainer>
                  {/* Template Details */}
                  <TemplateTitle>{template.title}</TemplateTitle>
                </OverlayContainer>
              </TemplateCardStyled>
            );
          })}
        </TemplatesGridStyled>
      </TemplatesGridWrapper>
    </div>
  );
};

// =========================
// Interface for props
// =========================
interface TemplateSelectionPageProps {
  onSelectTemplates: (templates: string[]) => void;
}

// =========================
/** Template Selection Page Component */
// =========================
const TemplateSelectionPage: React.FC<TemplateSelectionPageProps> = ({
  onSelectTemplates,
}) => {
  const navigate = useNavigate();
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);

  const [user] = useAuthState(auth);

  // State for animated photo count
  const [animatedPhotos, setAnimatedPhotos] = useState<number>(0);
  const photosRef = useRef<number>(0);

  useEffect(() => {
    const targetPhotos = selectedTemplates.length * 30;
    const duration = 500; // duration of animation in ms
    const frameRate = 60; // frames per second
    const totalFrames = (duration / 1000) * frameRate;
    const increment = (targetPhotos - photosRef.current) / totalFrames;
    let frame = 0;

    const animate = () => {
      frame++;
      const newCount = Math.round(photosRef.current + increment);
      setAnimatedPhotos(newCount);
      photosRef.current = newCount;
      if (frame < totalFrames) {
        requestAnimationFrame(animate);
      } else {
        setAnimatedPhotos(targetPhotos);
        photosRef.current = targetPhotos;
      }
    };

    animate();
  }, [selectedTemplates]);

  const handleTemplateSelect = (templateId: string) => {
    const isSelected = selectedTemplates.includes(templateId);

    if (isSelected) {
      setSelectedTemplates(selectedTemplates.filter((id) => id !== templateId));
    } else {
      setSelectedTemplates([...selectedTemplates, templateId]);
    }

    const template = templateService.findTemplateById(templateId);

    if (analytics && template) {
      logEvent(analytics, 'template_selected', {
        template_id: template.id,
        template_title: template.title,
        is_paid: template.isPaid ? 'yes' : 'no',
        is_discounted: template.isDiscounted ? 'yes' : 'no',
        category_name: getCategoryNameByTemplateId(template.id),
      });
    }
  };

  const getCategoryNameByTemplateId = (templateId: string): string => {
    for (const category of templateCategories) {
      if (category.templates.some((template) => template.id === templateId)) {
        return category.name;
      }
    }
    return 'Unknown';
  };

  const handleNext = () => {
    if (selectedTemplates.length > 0) {
      onSelectTemplates(selectedTemplates);

      // Calculate total price
      const totalPrice = selectedTemplates.reduce((total, id) => {
        const template = templateService.findTemplateById(id);
        if (!template) return total;

        const price = 4.0;

        if (template.isPaid === false) {
          return total; // Free template, no cost added
        } else if (template.isDiscounted) {
          return total + (price * 0.75);
        } else {
          return total + price;
        }
      }, 0);

      // Log event when 'Next' button is clicked
      if (analytics) {
        logEvent(analytics, 'step1_next_button_clicked', {
          selected_templates_count: selectedTemplates.length,
          total_price: totalPrice.toFixed(2),
        });
      }

      navigate('/upload-photos');
    } else {
      toast.warn('Please select at least one template to proceed.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };

  // Calculate total price
  const totalPrice = selectedTemplates.reduce((total, id) => {
    const template = templateService.findTemplateById(id);
    if (!template) return total;

    const price = 4.0;

    if (template.isPaid === false) {
      return total; // Free template, no cost added
    } else if (template.isDiscounted) {
      return total + (price * 0.75);
    } else {
      return total + price;
    }
  }, 0);

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <FullscreenContainer>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
        />

        <ContentContainer>
          <HeaderContainer>
            <ProgressBarContainer>
              <ProgressBarFiller percentage={33} />
            </ProgressBarContainer>
            <StepIndicator>Step 1 of 3</StepIndicator>
            <TitleRow>
              <Title>Choose Your Styles</Title>
              {selectedTemplates.length > 0 && (
                <PhotoCount>
                  {animatedPhotos} Portrait{animatedPhotos !== 1 ? 's' : ''}
                </PhotoCount>
              )}
            </TitleRow>

            <TotalInfoDisplay>
              <span>
                Total Price: <strong>${totalPrice.toFixed(2)}</strong>
              </span>
            </TotalInfoDisplay>
          </HeaderContainer>

          {/* Templates by Category */}
          {templateCategories.map((category) => (
            <TemplateCategorySection
              key={category.name}
              category={category}
              selectedTemplates={selectedTemplates}
              handleTemplateSelect={handleTemplateSelect}
              getCategoryNameByTemplateId={getCategoryNameByTemplateId}
            />
          ))}
        </ContentContainer>

        {/* Bottom Navigation */}
        <BottomNav>
          <NavButton
            primary
            onClick={handleNext}
            aria-label="Proceed to Next Step"
            disabled={selectedTemplates.length === 0}
          >
            Next
          </NavButton>
        </BottomNav>
      </FullscreenContainer>
    </>
  );
};

export default TemplateSelectionPage;
