// src/components/PurchasePage.tsx

import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { storage, db, auth, analytics } from './firebaseConfig';
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { templateCategories, TemplateService } from './templates';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { logEvent } from 'firebase/analytics';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS
import Navbar from './Navbar';
import { FaInfoCircle, FaArrowCircleRight, FaGem } from 'react-icons/fa';

// Interface for props
interface PurchasePageProps {
  photos: File[];
  gender: string;
  ethnicity: string;
  templates: string[];
}

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_live_51Q7mlYAo8DkCAAeqKbJ2AkantXN1kH4UOBXESsBNI7leUB3cNCcYTdruYLNT1yrpJ92ZlvbQJeFK0seBqkJVMH7A00ilD1QgC0');

const colors = {
  primary: '#007BFF',
  secondary: '#6C757D',
  success: '#28A745',
  danger: '#DC3545',
  warning: '#FFC107',
  info: '#17A2B8',
  light: '#F8F9FA',
  dark: '#343A40',
  white: '#FFFFFF',
  muted: '#6C757D',
  gray: '#6C757D',
  onBackground: '#111827',
  onPrimary: '#FFFFFF',
};

const PurchasePage: React.FC<PurchasePageProps> = ({
  photos,
  gender,
  ethnicity,
  templates,
}) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [user] = useAuthState(auth);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState<boolean>(false);
  const [hasUpgraded, setHasUpgraded] = useState<boolean>(false);
  const [isUserEligibleForFreeOrder, setIsUserEligibleForFreeOrder] = useState<boolean>(false);

  // Instantiate the TemplateService
  const templateService = new TemplateService(templateCategories);

  useEffect(() => {
    const checkFreeOrderEligibility = async () => {
      if (user && user.email) {
        try {
          const q = query(
            collection(db, 'free_order_users'),
            where('email', '==', user.email)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            setIsUserEligibleForFreeOrder(true);
          }
        } catch (error) {
          console.error('Error checking free order eligibility:', error);
        }
      }
    };

    checkFreeOrderEligibility();
  }, [user]);

  // Calculate total price
  const calculateTotalPrice = (
    templateIds: string[],
    upgrade: boolean,
    isUserEligibleForFreeOrder: boolean
  ) => {
    const basePrice = 4.0;
    let total = 0;
    let templatesToCharge = templateIds;

    if (isUserEligibleForFreeOrder) {
      // If they have selected more than 5 templates
      if (templateIds.length > 3) {
        // The first 5 are free
        templatesToCharge = templateIds.slice(3);
      } else {
        templatesToCharge = [];
      }
    }

    total = templatesToCharge.reduce((sum, id) => {
      const template = templateService.findTemplateById(id);
      if (!template || template.isPaid === false) return sum;
      return sum + (template.isDiscounted ? basePrice * 0.75 : basePrice);
    }, 0);

    if (upgrade) {
      total += 1.5; // Add the upsell price if the user opted for it
    }

    return total;
  };

  const totalPrice = calculateTotalPrice(templates, hasUpgraded, isUserEligibleForFreeOrder);

  // Function to handle upgrade
  const handleUpgrade = () => {
    setHasUpgraded(true);
  };

  // Function to handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const userEmail = user?.email;

  const handlePurchase = async () => {
    try {
      await validateInput();
      setIsProcessing(true);
      const orderId = uuidv4();

      const orderData = {
        orderId,
        userId: user?.uid,
        userEmail: userEmail,
        gender: gender,
        ethnicity: ethnicity,
        templateIds: templates,
        status: 'inProgress',
        timestamp: new Date().toISOString(),
      };

      await createFirestoreEntry(orderId, orderData);
      await new Promise((resolve) => setTimeout(resolve, 500));
      await uploadPhotos(orderId);
      await sendOrder(orderData);

      // If the user is using a free order voucher, then remove them from this list as they have now used it.
      if (isUserEligibleForFreeOrder && user && user.email) {
        await removeUserFromFreeOrderUsers(user.email);
      }

      setIsProcessing(false);

      if (analytics) {
        if (totalPrice > 0) {
          logEvent(analytics, 'paid_order', {
            price: totalPrice.toFixed(2),
            templates_selected: templates.length,
          });
        } else {
          logEvent(analytics, 'free_order', {
            templates_selected: templates.length,
          });
        }
      }

      // // Navigate to Orders Page
      navigate(`/orders?order_id=${orderId}`);
    } catch (error: any) {
      console.error('Error during purchase process:', error);
      alert(error.message || 'Error during purchase. Please try again.');
      setIsProcessing(false);
    }
  };

  const removeUserFromFreeOrderUsers = async (email: string) => {
    try {
      const q = query(
        collection(db, 'free_order_users'),
        where('email', '==', email)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Assuming email is unique and there's only one document
        const docId = querySnapshot.docs[0].id;
        await deleteDoc(doc(db, 'free_order_users', docId));
        console.log(`Removed ${email} from free_order_users collection.`);
        setIsUserEligibleForFreeOrder(false); // Update state
      }
    } catch (error) {
      console.error('Error removing user from free_order_users:', error);
      // You might want to handle this error differently depending on your requirements
    }
  };

  const validateInput = async () => {
    if (!userEmail) {
      throw new Error('User is not authenticated.');
    }

    if (photos.length === 0) {
      throw new Error('No photos to upload!');
    }

    if (templates.length === 0) {
      throw new Error('Please select at least one template.');
    }

    if (!ethnicity) {
      throw new Error('Please select an ethnicity.');
    }

    if (!gender) {
      throw new Error('Please select a gender.');
    }
  };

  const uploadPhotos = async (orderId: string): Promise<string[]> => {
    const uploadPromises = photos.map((photo) => {
      const storageRef = ref(storage, `uploaded_photos/${orderId}/${photo.name}`);
      return uploadBytes(storageRef, photo)
        .then((snapshot) => getDownloadURL(snapshot.ref))
        .catch((error) => {
          console.error(`Error uploading file ${photo.name}: `, error);
          throw error;
        });
    });

    const urls = await Promise.all(uploadPromises);
    console.log('All files uploaded:', urls);
    return urls;
  };

  const createFirestoreEntry = async (orderId: string, orderData: any) => {
    try {
      await setDoc(doc(db, 'orders', orderId), orderData);
      console.log(`Firestore entry created successfully for order ID: ${orderId}`);
    } catch (error) {
      console.error('Error creating Firestore entry:', error);
      throw new Error('Failed to create order in Firestore.');
    }
  };

  const sendOrder = async (orderData: any) => {
    if (user) {
      const idToken = await user.getIdToken();

      const response = await fetch(
        'https://us-central1-photoop-28a4c.cloudfunctions.net/publish_order',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(orderData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response from publish_order:', errorText);
        throw new Error('Failed to send order.');
      }
    } else {
      throw new Error('User is not authenticated.');
    }
  };

  const handleBack = () => {
    navigate('/upload-photos');
  };

  // PaymentForm Component
  const PaymentForm: React.FC<{ amount: number }> = ({ amount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsProcessingPayment(true);

      try {
        if (!user) {
          setErrorMessage('User not authenticated');
          return;
        }

        const idToken = await user.getIdToken();

        const response = await fetch(
          'https://us-central1-photoop-28a4c.cloudfunctions.net/create_payment_intent',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({ amount: Math.round(amount * 100) }), // amount in cents
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Response from create_payment_intent:', errorText);
          throw new Error('Failed to create payment intent');
        }

        const data = await response.json();

        const clientSecret = data.clientSecret;

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: {
              email: user.email || undefined,
            },
          },
        });

        if (result.error) {
          setErrorMessage(result.error.message || 'Payment failed');
        } else {
          if (result.paymentIntent?.status === 'succeeded') {
            setIsPaymentCompleted(true);
            handlePurchase();
          }
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        setErrorMessage('An error occurred during payment.');
      } finally {
        setIsProcessingPayment(false);
      }
    };

    return (
      <PaymentFormContainer onSubmit={handleSubmit}>
        <FormTitle>
          Secure Payment <LockIcon className="fas fa-lock" />
        </FormTitle>
        <DescriptionCentered>
          Complete your purchase securely. All transactions are encrypted.
        </DescriptionCentered>
        <CardElementContainer>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: '#495057',
                  fontFamily: "'Roboto', sans-serif",
                  '::placeholder': {
                    color: '#6c757d',
                  },
                },
                invalid: {
                  color: '#dc3545',
                },
              },
            }}
          />
        </CardElementContainer>
        <PaymentButton type="submit" disabled={!stripe || isProcessingPayment}>
          {isProcessingPayment ? 'Processing Payment...' : `Pay $${amount.toFixed(2)}`}
        </PaymentButton>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <SecurityNote>
          <i className="fas fa-shield-alt"></i> Your information is protected.
        </SecurityNote>
      </PaymentFormContainer>
    );
  };

  return (
    <>
      <GlobalStyle />
      <FullscreenContainer>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
        />

        {!user ? (
          <ContentContainer>
            <TextContainer>
              <Title>Please Sign In to Complete Your Order</Title>
              <Description>
                Sign in with Google to proceed with your purchase and generate your
                personalized AI portraits.
              </Description>
              <GoogleSignInButton onClick={handleGoogleSignIn}>
                <GoogleIcon className="fab fa-google" />
                Sign in with Google
              </GoogleSignInButton>
            </TextContainer>
          </ContentContainer>
        ) : (
          <ContentContainer>
            {/* Left Side - Text */}
            <OrderSection>
              <ProgressBarContainer>
                <ProgressBarFiller percentage={100} />
              </ProgressBarContainer>
              <StepIndicator>Step 3 of 3</StepIndicator>
              <Title>Review and complete your order.</Title>
              <Description>
                You're just one step away from generating your personalized AI portraits.
                Please review your order and complete the payment below.
              </Description>

              {/* Upsell Section for Free Orders */}
              {totalPrice === 0 && !hasUpgraded && (
                <UpsellContainer>
                  <UpsellIconContainer>
                    <FaGem size={48} color="#2563EB" />
                  </UpsellIconContainer>
                  <UpsellContent>
                    <UpsellTitle>Upgrade to High-Quality </UpsellTitle>
                    <UpsellText>
                      Your free order will produce standard quality images. Upgrade to get stunning <strong>high-resolution images</strong>. $1.50
                    </UpsellText>
                    <UpgradeButton onClick={handleUpgrade}>
                      Upgrade Now <FaArrowCircleRight style={{ marginLeft: '6px' }} />
                    </UpgradeButton>
                  </UpsellContent>
                </UpsellContainer>
              )}

              {hasUpgraded && (
                <UpsellConfirmed>
                  <FaInfoCircle size={18} style={{ marginRight: '8px' }} />
                  <strong>Image Quality Upgrade Applied</strong> Your images will be enhanced as part of this order.
                </UpsellConfirmed>
              )}

              {

                totalPrice > 0 ? (
                  <OrderSummary>
                    <SummaryTitle>Order Summary</SummaryTitle>
                    <SummaryItem>
                      <span>Templates Selected:</span>
                      <span>{templates.length}</span>
                    </SummaryItem>
                    <SummaryItem>
                      <span>Total Images:</span>
                      <span>{templates.length * 30}</span>
                    </SummaryItem>
                    <SummaryItem>
                      <span>Gender:</span>
                      <span>{gender}</span>
                    </SummaryItem>
                    <SummaryItem>
                      <span>Ethnicity:</span>
                      <span>{ethnicity}</span>
                    </SummaryItem>
                    <SummaryTotal>
                      <span>Total Price:</span>
                      <span>${totalPrice.toFixed(2)}</span>
                    </SummaryTotal>
                  </OrderSummary>
                ) : <div></div>
              }
            </OrderSection>

            {/* Right Side - Purchase Section */}
            <PaymentSection>
              {!isPaymentCompleted ? (
                totalPrice > 0 ? (
                  <Elements stripe={stripePromise}>
                    <PaymentForm amount={totalPrice} />
                  </Elements>
                ) : (
                  <div>
                    <OrderSummary>
                      <SummaryTitle>Order Summary</SummaryTitle>
                      <SummaryItem>
                        <span>Templates Selected:</span>
                        <span>{templates.length}</span>
                      </SummaryItem>
                      <SummaryItem>
                        <span>Total Images:</span>
                        <span>{templates.length * 30}</span>
                      </SummaryItem>
                      <SummaryItem>
                        <span>Gender:</span>
                        <span>{gender}</span>
                      </SummaryItem>
                      <SummaryItem>
                        <span>Ethnicity:</span>
                        <span>{ethnicity}</span>
                      </SummaryItem>
                      <SummaryTotal>
                        <span>Total Price:</span>
                        <span>${totalPrice.toFixed(2)}</span>
                      </SummaryTotal>
                    </OrderSummary>

                    <PurchaseButton
                      onClick={handlePurchase}
                      disabled={isProcessing}
                      aria-label="Complete Purchase"
                    >
                      {isProcessing ? 'Processing...' : 'Get My Portraits'}
                      <i className="fas fa-circle-check" aria-hidden="true"></i>
                    </PurchaseButton>
                  </div>

                )
              ) : (
                <SuccessMessage>
                  <h2>Payment Successful!</h2>
                  <p>Your order is being processed.</p>
                </SuccessMessage>
              )}
            </PaymentSection>
          </ContentContainer>
        )}

        {/* Bottom Navigation */}
        <BottomNav>
          <NavButton onClick={handleBack}>Back</NavButton>
        </BottomNav>
      </FullscreenContainer>
    </>
  );
};

export default PurchasePage;

// Styled Components
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    background-color: #f5f7fa;
    color: #333;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(18px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Fullscreen container for the entire page
const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

// Content container to position text and payment form side by side
const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  flex: 1;
  padding: 54px 72px;
  animation: ${fadeIn} 0.8s ease-in;

  @media (max-width: 1024px) {
    padding: 45px 54px;
  }

  @media (max-width: 768px) {
    padding: 36px 27px;
  }
`;

// Order Section
const OrderSection = styled.div`
  flex: 1;
  min-width: 270px;
  max-width: 450px;
  margin-right: 45px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 36px;
    width: 100%;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #E5E7EB;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 18px;
`;

const ProgressBarFiller = styled.div<{ percentage: number }>`
  height: 8px;
  width: ${(props) => props.percentage}%;
  background-color: ${colors.primary};
  transition: width 0.4s ease-in-out;
`;

const StepIndicator = styled.h5`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${colors.onBackground};
  margin-bottom: 9px;
  text-transform: uppercase;
  letter-spacing: 0.9px;
`;

const Title = styled.h1`
  font-size: 2.34rem;
  font-weight: 700;
  margin-bottom: 18px;
  color: #111;

  @media (max-width: 768px) {
    font-size: 2.16rem;
  }

  @media (max-width: 480px) {
    font-size: 1.98rem;
  }
`;

const Description = styled.p`
  font-size: 0.99rem;
  font-weight: 400;
  color: #555;
  line-height: 1.6;
  margin-bottom: 22.5px;
`;

const OrderSummary = styled.div`
  background-color: #ffffff;
  border: 0.9px solid #e0e0e0;
  padding: 27px;
  border-radius: 7.2px;
  margin-top: 18px;

  @media (max-width: 768px) {
    padding: 18px;
  }
`;

const SummaryTitle = styled.h3`
  font-size: 1.35rem;
  font-weight: 600;
  margin-bottom: 18px;
  color: #333;
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13.5px;
  font-size: 0.9rem;
  color: #555;

  span:first-child {
    font-weight: 500;
  }
`;

const SummaryTotal = styled(SummaryItem)`
  font-size: 1.08rem;
  font-weight: 600;
  color: #111;
  margin-top: 18px;
`;

// Payment Section
const PaymentSection = styled.div`
  flex: 1;
  min-width: 270px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

// Payment Form Styled Components
const PaymentFormContainer = styled.form`
  width: 100%;
  background-color: ${colors.white};
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  border: 2px solid ${colors.primary};

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;


const LockIcon = styled.i`
  font-size: 1.5rem;
  color: ${colors.success};
  margin-left: 10px;
`;

const FormTitle = styled.h2`
  font-size: 1.8rem;
  color: ${colors.dark};
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DescriptionCentered = styled.p`
  font-size: 0.9rem;
  color: #555;
  text-align: center;
  margin-bottom: 22.5px;
`;

const CardElementContainer = styled.div`
  padding: 13.5px;
  border: 0.9px solid #e0e0e0;
  border-radius: 7.2px;
  margin-bottom: 22.5px;
  background-color: #fafafa;
`;

const PaymentButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: ${colors.dark};
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: ${colors.secondary};
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 13.5px;
  text-align: center;
`;

const SecurityNote = styled.div`
  margin-top: 18px;
  font-size: 0.81rem;
  color: #777;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: #28a745;
    margin-right: 7.2px;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;

  h2 {
    font-size: 1.62rem;
    color: #28a745;
    margin-bottom: 9px;
  }

  p {
    font-size: 0.9rem;
    color: #555;
  }
`;

const PurchaseButton = styled.button`
  gap: 12px;
  background: linear-gradient(135deg, #2563EB, #1D4ED8); 
  color: #ffffff;
  margin-top: 40px;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: background 0.3s ease, transform 0.1s ease;

  &:hover {
    background: linear-gradient(135deg, #1D4ED8, #1E40AF);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: ${colors.secondary};
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

// Styled Components for Sign-In Button
const GoogleSignInButton = styled.button`
  background-color: #4285F4;
  color: white;
  padding: 12.6px 25.2px;
  font-size: 0.9rem;
  margin-top: 27px;
  border: none;
  border-radius: 5.4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: #357AE8;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2.7px rgba(66, 133, 244, 0.5);
  }
`;

const GoogleIcon = styled.i`
  font-size: 1.08rem;
  margin-right: 9px;
`;

// Bottom Navigation Bar
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 24px;
  background-color: #ffffff;
  border-top: 0.9px solid #e0e0e0;

  @media (max-width: 768px) {
    padding: 13.5px 27px;
  }

  @media (max-width: 480px) {
    padding: 9px 18px;
  }
`;

const NavButton = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? colors.primary : 'transparent')};
  color: ${(props) => (props.primary ? colors.onPrimary : colors.primary)};
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: ${(props) => (props.primary ? 'none' : `2px solid ${colors.primary}`)};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.primary ? colors.secondary : `${colors.primary}1A`)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// Text Container
const TextContainer = styled.div`
  flex: 1;
  max-width: 540px;
  margin-right: 45px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 36px;
  }
`;

const UpsellContainer = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa, #e2e8f0);
  color: #2d3748;
  border: 1px solid #cbd5e0;
  padding: 24px;
  border-radius: 12px;
  margin-top: 40px;
  font-size: 0.9rem;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

// Update the UpsellIconContainer
const UpsellIconContainer = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

// Update the UpsellContent
const UpsellContent = styled.div`
  flex: 1;
`;

// Update the UpsellTitle
const UpsellTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #2d3748;
`;

// Update the UpsellText
const UpsellText = styled.p`
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 12px;
`;

// Update the UpgradeButton
const UpgradeButton = styled.button`
  background-color: #1D4ED8;
  color: ${colors.white};
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  margin-top: 24px;
  min-width: 250px;

  &:hover {
    background-color: #2b6cb0;
  }

  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

const UpsellConfirmed = styled.div`
  display: flex;
  align-items: center;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 16px;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 0.9rem;
  font-weight: bold;
`;