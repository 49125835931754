// src/components/OrderCompletePage.tsx

import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaEnvelope } from 'react-icons/fa';
import {
  ref,
  listAll,
  getDownloadURL,
} from 'firebase/storage';
import {
  collection,
  doc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
  onSnapshot,
} from 'firebase/firestore';
import { storage, db, auth } from './firebaseConfig';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import Navbar from './Navbar';
import OrderReview from './OrderReview';

// Import new icons
import { FaRobot, FaBolt, FaBrain } from 'react-icons/fa';

const createFirestoreEntry = async (orderId: string, reviewData: any) => {
  try {
    await setDoc(doc(db, 'reviews', orderId), reviewData);
    console.log(`Firestore entry created successfully for order ID: ${orderId}`);
  } catch (error) {
    console.error('Error creating Firestore entry:', error);
    throw new Error('Failed to create order in Firestore.');
  }
};

// Global Styles
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: #f5f5f5;
    color: #333333;
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
`;

// Styled components for progress stages
const ProgressStagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 50px 0;
  padding: 20px;
`;

const Stage = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 30px;
    left: calc(50% + 30px);
    width: calc(100% - 60px);
    height: 4px;
    background-color: ${(props) => (props.isCompleted ? '#0074a2' : '#cccccc')};
    z-index: 1;
  }
`;

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const spinnerBorderAnimation = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;


const SupportButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #005f80;
  }

  svg {
    margin-right: 8px;
  }
`;


const StageIcon = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  width: 60px;
  height: 60px;
  background-color: ${(props) =>
    props.isCompleted ? '#0074a2' : props.isActive ? '#0074a2' : '#cccccc'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2rem;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 2;

  svg {
    font-size: 2rem;
  }

  ${(props) =>
    props.isActive &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: -5px;
        left: -5px;
        width: 70px;
        height: 70px;
        border: 5px solid rgba(0, 116, 162, 0.3);
        border-top-color: #0074a2;
        border-radius: 50%;
        animation: ${spinnerBorderAnimation} 1s linear infinite;
      }
    `}
`;

const StageLabel = styled.p<{ isActive: boolean }>`
  margin-top: 10px;
  font-size: 1rem;
  font-weight: ${(props) => (props.isActive ? '600' : '400')};
  color: #333;
  text-align: center;
`;

const StageDescription = styled.p`
  margin-top: 5px;
  font-size: 0.9rem;
  color: #777;
  text-align: center;
  max-width: 150px;
`;

// Update stagesData with imported icons
const stagesData = [
  { label: 'Processing Order', icon: <FaBolt />, description: 'We’re processing your order details.' },
  { label: 'Training Model', icon: <FaBrain />, description: 'Your custom model is being trained.' },
  { label: 'Generating Photos', icon: <FaRobot />, description: 'We’re generating your portraits.' },
];

const ProgressStages = ({ currentStage }: { currentStage: number }) => (
  <ProgressStagesContainer>
    {stagesData.map((stage, index) => (
      <Stage key={index} isActive={index === currentStage} isCompleted={index < currentStage}>
        <StageIcon isActive={index === currentStage} isCompleted={index < currentStage}>
          {stage.icon}
        </StageIcon>
        <StageLabel isActive={index === currentStage}>{stage.label}</StageLabel>
        {index === currentStage && <StageDescription>{stage.description}</StageDescription>}
      </Stage>
    ))}
  </ProgressStagesContainer>
);

// Styled Components for OrderCompletePage
const Container = styled.div`
  padding: 60px 80px;
  flex: 1;
  animation: ${fadeIn} 1s ease-in;

  @media (max-width: 768px) {
    padding: 40px 30px;
  }

  @media (max-width: 480px) {
    padding: 30px 20px;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  color: #111;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

const Message = styled.p`
  font-size: 1rem;
  color: #555;
`;

const ErrorMessage = styled.p`
  font-size: 1rem;
  color: red;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.15);
  object-fit: cover;
`;

const AccordionItem = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AccordionHeader = styled.div<{ isOpen: boolean }>`
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => (props.isOpen ? '#f0f8ff' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }
`;


const OrderHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

const OrderTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
`;

const OrderMeta = styled.span<{ isOpen: boolean }>`
  font-size: 0.9rem;
  color: ${(props) => (props.isOpen ? '#ffffff' : '#555555')};
  margin-top: 4px;
  line-height: 1.4;
`;

const AccordionIcon = styled.span<{ isOpen: boolean }>`
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const GoogleSignInButton = styled.button`
  background-color: #4285F4;
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #357AE8;
  }
`;

const DownloadButton = styled.a`
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #0074a2;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #006080;
  }
`;

// Styled components for In-Progress Animation
const InProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  margin: 20px 0;
  text-align: center;
`;

const InProgressIcon = styled.div`
  font-size: 4rem;
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-in;
  color: #0074a2;
`;

const InProgressTitle = styled.h2`
  font-size: 1.8rem;
  // margin-bottom: 20px;
  animation: ${fadeIn} 1.2s ease-in;
  color: #111;
`;

const InProgressMessage = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  max-width: 600px;
  text-align: center;
  animation: ${fadeIn} 1.4s ease-in;
  color: #555;
`;

const ProgressContainer = styled.div`
  width: 100%;
  max-width: 800px;
  // margin-top: 40px;
  animation: ${fadeIn} 1.6s ease-in;
`;

const Footer = styled.footer`
  padding: 20px 60px;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 15px 30px;
  }
`;

const FooterText = styled.p`
  color: #777;
  font-size: 0.9rem;
`;

// Interface for order data
interface OrderData {
  orderId: string;
  imageUrls: string[];
  inProgress?: boolean;
  timestamp?: string; // Should be a valid date string
  templateIds?: string[];
  ethnicity?: string;
  gender?: string;
  status?: string;
}

const OrderCompletePage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [orders, setOrders] = useState<OrderData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState('');
  const [openOrderIds, setOpenOrderIds] = useState<string[]>([]);
  const [user] = useAuthState(auth);
  const [isOrderInProgress, setIsOrderInProgress] = useState(false);

  // State for reviews
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>('');
  const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);

  const orderId = searchParams.get('orderId') || searchParams.get('order_id');

  const handleReviewSubmit = async (orderId: string) => {
    if (!user) return;

    try {
      await createFirestoreEntry(orderId, {
        orderId,
        userId: user.uid,
        userEmail: user.email,
        rating,
        feedback,
        timestamp: new Date().toISOString(),
      });

      setIsReviewSubmitted(true);
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  const toggleOrder = (orderId: string) => {
    if (openOrderIds.includes(orderId)) {
      setOpenOrderIds(openOrderIds.filter((id) => id !== orderId));
    } else {
      setOpenOrderIds([...openOrderIds, orderId]);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement>, urls: string[]) => {
    e.preventDefault();
    urls.forEach((url) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('?')[0].split('/').pop() || 'image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const computeCurrentStage = (orderTimestamp?: string, imageUrls?: string[]): number => {
    if (imageUrls && imageUrls.length > 0) {
      return 2; // Images are ready
    }
    if (!orderTimestamp) return 0;
    const orderTime = new Date(orderTimestamp);
    const now = new Date();
    const timeDiff = now.getTime() - orderTime.getTime();
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    if (hoursDiff < 1) {
      return 0;
    } else if (hoursDiff < 2) {
      return 1;
    } else {
      return 2;
    }
  };

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const fetchOrders = () => {
      setIsLoading(true);
      setFetchError('');
      setOrders([]);
      setIsOrderInProgress(false);

      if (orderId) {
        // Fetch images and metadata for the specific orderId
        const orderDocRef = doc(db, 'orders', orderId);

        unsubscribe = onSnapshot(
          orderDocRef,
          async (docSnapshot) => {
            if (docSnapshot.exists()) {
              const orderData = docSnapshot.data() as OrderData;
              const orderId = orderData.orderId;

              // Use imageUrls from orderData or fetch from Storage
              let imageUrls: string[] = orderData.imageUrls || [];
              if (imageUrls.length === 0) {
                // Fetch from Storage if necessary
                const storageRef = ref(storage, `user_outputs/${orderId}`);
                const fileList = await listAll(storageRef);

                if (fileList.items.length > 0) {
                  const urlPromises = fileList.items.map((fileRef) =>
                    getDownloadURL(fileRef)
                  );
                  imageUrls = await Promise.all(urlPromises);
                } else {
                  setIsOrderInProgress(true);
                }
              }

              setOrders([{ ...orderData, orderId, imageUrls }]);
              setOpenOrderIds([orderId]);
              setIsOrderInProgress(false);
              setIsLoading(false);
            } else {
              setFetchError(`Order ID ${orderId} not found.`);
              setIsLoading(false);
            }
          },
          (error) => {
            console.error(`Error fetching order ${orderId}:`, error);
            setFetchError(`Error fetching order ID: ${orderId}. ${user?.uid}`);
            setIsLoading(false);
          }
        );
      } else if (user) {
        // Fetch orders for authenticated user
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('userId', '==', user.uid),
          orderBy('timestamp', 'desc'),
          limit(5)
        );

        unsubscribe = onSnapshot(
          q,
          async (querySnapshot) => {
            if (querySnapshot.empty) {
              setFetchError('No orders found for your account.');
              setIsLoading(false);
              return;
            }

            const fetchedOrders = await Promise.all(
              querySnapshot.docs.map(async (doc) => {
                const orderData = doc.data() as OrderData;
                const orderId = orderData.orderId;
                if (!orderId) return null;

                let imageUrls: string[] = orderData.imageUrls || [];
                if (imageUrls.length === 0) {
                  // Fetch from Storage if necessary
                  const storageRef = ref(storage, `user_outputs/${orderId}`);
                  const fileList = await listAll(storageRef);

                  if (fileList.items.length > 0) {
                    const urlPromises = fileList.items.map((fileRef) =>
                      getDownloadURL(fileRef)
                    );
                    imageUrls = await Promise.all(urlPromises);
                  }
                }

                return {
                  ...orderData,
                  orderId,
                  imageUrls,
                };
              })
            );

            setOrders(
              fetchedOrders.filter((order) => order !== null) as OrderData[]
            );
            setIsLoading(false);
          },
          (error) => {
            console.error('Error fetching orders:', error);
            setFetchError('An error occurred while fetching your orders.');
            setIsLoading(false);
          }
        );
      } else {
        setIsLoading(false);
      }
    };

    fetchOrders();

    // Clean up the listener on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [user, orderId]);


  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
        />

        <Container>
          {isOrderInProgress ? (
            <InProgressContainer>
              <InProgressIcon>
                <FaRobot />
              </InProgressIcon>
              <InProgressTitle>Your Portraits Are Being Crafted!</InProgressTitle>
              {/* <InProgressMessage>
                Our AI is hard at work creating your unique portraits.
              </InProgressMessage> */}
              <ProgressContainer>
                <ProgressStages currentStage={computeCurrentStage(orders[0]?.timestamp)} />
              </ProgressContainer>
              <InProgressMessage>
                Orders typically take <strong>3 hours</strong> to complete.
                <br />
                You'll receive an email once your portraits are ready.
              </InProgressMessage>
            </InProgressContainer>
          ) : (
            <>
              <HeaderContainer>
                <Title>Your Orders</Title>
                <SupportButton href="mailto:owenkamilkhoury@example.com?subject=Order%20Support">
                  <FaEnvelope />
                  Contact Support
                </SupportButton>
              </HeaderContainer>
              {isLoading && <Message>Loading your orders...</Message>}
              {fetchError && <ErrorMessage>{fetchError}</ErrorMessage>}

              {!orderId && !user && (
                <>
                  <Message>Please sign in to view your orders.</Message>
                  <GoogleSignInButton onClick={handleGoogleSignIn}>
                    Sign in with Google
                  </GoogleSignInButton>
                </>
              )}

              {orders.length > 0 ? (
                <>
                  {orders.map((order) => {
                    const currentStage = computeCurrentStage(order.timestamp, order.imageUrls);
                    return (
                      <AccordionItem key={order.orderId}>
                        <AccordionHeader
                          onClick={() => toggleOrder(order.orderId)}
                          isOpen={openOrderIds.includes(order.orderId)}
                          aria-expanded={openOrderIds.includes(order.orderId)}
                          role="button"
                        >
                          <OrderHeaderContent>
                            <OrderTitle>Order Summary</OrderTitle>
                            <OrderMeta isOpen={openOrderIds.includes(order.orderId)}>
                              Placed on:{' '}
                              {order.timestamp
                                ? new Date(order.timestamp).toLocaleDateString('en-US', {
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric',
                                })
                                : 'N/A'}
                            </OrderMeta>
                          </OrderHeaderContent>
                          <AccordionIcon isOpen={openOrderIds.includes(order.orderId)}>
                            {openOrderIds.includes(order.orderId) ? <FaChevronUp /> : <FaChevronDown />}
                          </AccordionIcon>
                        </AccordionHeader>
                        <AccordionContent isOpen={openOrderIds.includes(order.orderId)}>
                          <OrderReview
                            rating={rating}
                            setRating={setRating}
                            feedback={feedback}
                            setFeedback={setFeedback}
                            onSubmit={() => handleReviewSubmit(order.orderId)}
                            isSubmitted={isReviewSubmitted}
                          />
                          {order.imageUrls.length > 0 ? (
                            <>
                              <ImageGrid>
                                {order.imageUrls.map((url, index) => (
                                  <Image
                                    key={index}
                                    src={url}
                                    alt={`Order ${order.orderId} - Image ${index + 1}`}
                                    loading="lazy"
                                  />
                                ))}
                              </ImageGrid>
                              <DownloadButton
                                href="#"
                                onClick={(e) => handleDownload(e, order.imageUrls)}
                              >
                                Download All Images
                              </DownloadButton>
                            </>
                          ) : (
                            <InProgressContainer>
                              <InProgressIcon>
                                <FaRobot />
                              </InProgressIcon>
                              <InProgressTitle>
                                Your Portraits Are Being Crafted!
                              </InProgressTitle>
                              <ProgressContainer>
                                <ProgressStages currentStage={currentStage} />
                              </ProgressContainer>
                              <InProgressMessage>
                                Orders typically take <strong>3 hours</strong> to complete.
                                <br />
                                You'll receive an email once your portraits are ready.
                              </InProgressMessage>
                            </InProgressContainer>
                          )}

                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </>
              ) : (
                !isLoading &&
                !fetchError && <Message>You have no orders yet.</Message>
              )}
            </>
          )}
        </Container>

        {/* Footer */}
        <Footer>
          <FooterText>
            &copy; {new Date().getFullYear()} PhotoOp. All rights reserved.
          </FooterText>
        </Footer>
      </PageWrapper>
    </>
  );
};

export default OrderCompletePage;
