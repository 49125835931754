// src/components/PhotoUpload.tsx

import React, { useState, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

// =========================
// Global Styles
// =========================
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
  body {
    font-family: 'Roboto', sans-serif;
  }
`;

// =========================
// Color Palette
// =========================
const colors = {
  primary: '#2563EB', // Blue-600
  secondary: '#3B82F6', // Blue-500
  background: '#F9FAFB', // Gray-50
  surface: '#FFFFFF',
  error: '#DC2626', // Red-600
  onPrimary: '#FFFFFF',
  onBackground: '#111827', // Gray-900
};

// =========================
// Styled Components
// =========================

// Container for the entire component
const Container = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${colors.surface};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Header Section
const Header = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  color: ${colors.onBackground};
  margin-bottom: 8px;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: #6B7280; // Gray-500
`;

// Dropzone Area
const Dropzone = styled.div<{ isDragActive: boolean }>`
  border: 2px dashed ${(props) => (props.isDragActive ? colors.primary : '#D1D5DB')};
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  background-color: ${(props) => (props.isDragActive ? '#EFF6FF' : colors.background)};
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  margin-bottom: 24px;

  &:hover {
    background-color: #F3F4F6; // Gray-100
  }
`;

const DropzoneIcon = styled.div`
  font-size: 3rem;
  color: ${colors.primary};
  margin-bottom: 16px;
`;

const DropzoneText = styled.p`
  font-size: 1rem;
  color: ${colors.onBackground};
  margin-bottom: 0;

  strong {
    color: ${colors.primary};
  }
`;

// Hidden file input
const UploadInput = styled.input`
  display: none;
`;

// Scrollable gallery container
const GalleryContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 16px;
`;

// Gallery of uploaded images
const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 12px;
`;

// Remove Button
const RemoveButton = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(31, 41, 55, 0.8); // Gray-800 with opacity
  color: ${colors.onPrimary};
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  padding: 0;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    background-color: rgba(31, 41, 55, 1); // Gray-800
  }
`;

// Wrapper for each photo preview
const PhotoWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: #E5E7EB; // Gray-300
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover ${RemoveButton} {
    opacity: 1;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// Guidelines List
const GuidelinesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const GuidelineItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
`;

const GuidelineIcon = styled.span`
  width: 24px;
  height: 24px;
  background-color: ${colors.primary};
  color: ${colors.onPrimary};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 1rem;
  flex-shrink: 0;
`;

const GuidelineText = styled.span`
  font-size: 1rem;
  color: ${colors.onBackground};
  line-height: 1.4;
`;

// Add More Button
const AddMoreButton = styled.button`
  margin-top: 16px;
  padding: 12px 24px;
  background-color: ${colors.primary};
  color: ${colors.onPrimary};
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: ${colors.secondary};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }
`;

const ButtonIcon = styled.span`
  font-size: 1.25rem;
  margin-right: 8px;
`;

// Error message
const ErrorMessage = styled.p`
  color: ${colors.error};
  font-size: 0.9rem;
  text-align: center;
  margin-top: 8px;
`;

// Interface for props
interface PhotoUploadProps {
  onPhotoUpload: (newPhotos: File[]) => void;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({ onPhotoUpload }) => {
  const [photos, setPhotos] = useState<File[]>([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState('');

  // Handle file uploads
  const handlePhotoUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      const remainingSlots = 12 - photos.length;
      const newPhotos = Array.from(files).slice(0, remainingSlots);

      // Filter out files that are not images
      const imageFiles = newPhotos.filter((file) => file.type.startsWith('image/'));

      if (imageFiles.length < newPhotos.length) {
        setError('Only image files are allowed.');
      } else {
        setError('');
      }

      setPhotos((prevPhotos) => [...prevPhotos, ...imageFiles]);
      onPhotoUpload([...photos, ...imageFiles]);
    }
  };

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handlePhotoUpload(event.target.files);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Remove photo
  const handleRemovePhoto = (index: number) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
    onPhotoUpload(updatedPhotos);
  };

  // Drag and drop handlers
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(false);
    handlePhotoUpload(event.dataTransfer.files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragActive(false);
  };

  // Open file dialog
  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          <Title>Upload at least 5 photos</Title>
          {/* <Subtitle>
            Upload up to <strong>12 high-resolution images</strong> to generate personalized AI portraits.
          </Subtitle> */}
        </Header>

        <Dropzone
          isDragActive={isDragActive}
          onClick={openFileDialog}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          <DropzoneIcon>
            <i className="fas fa-cloud-upload-alt" aria-hidden="true"></i>
          </DropzoneIcon>
          <DropzoneText>
            {photos.length === 0 ? (
              <>
                Drag & Drop your images here or <strong>Browse</strong>
              </>
            ) : (
              <>
                Click to add more images or drag & drop
              </>
            )}
          </DropzoneText>
          <UploadInput
            ref={fileInputRef}
            type="file"
            accept="image/*"
            multiple
            onChange={handleInputChange}
          />
        </Dropzone>

        {error && <ErrorMessage>{error}</ErrorMessage>}

        {photos.length === 0 && (
          <GuidelinesList>
            <GuidelineItem>
              <GuidelineIcon>
                <i className="fas fa-check" aria-hidden="true"></i>
              </GuidelineIcon>
              <GuidelineText>Use photos with good lighting.</GuidelineText>
            </GuidelineItem>
            <GuidelineItem>
              <GuidelineIcon>
                <i className="fas fa-check" aria-hidden="true"></i>
              </GuidelineIcon>
              <GuidelineText>Ensure no other people are in the photos.</GuidelineText>
            </GuidelineItem>
            <GuidelineItem>
              <GuidelineIcon>
                <i className="fas fa-check" aria-hidden="true"></i>
              </GuidelineIcon>
              <GuidelineText>Include a variety of angles and expressions.</GuidelineText>
            </GuidelineItem>
          </GuidelinesList>
        )}

        {photos.length > 0 && (
          <>
            <GalleryContainer>
              <Gallery>
                {photos.map((photo, index) => (
                  <PhotoWrapper key={index}>
                    <Photo
                      src={URL.createObjectURL(photo)}
                      alt={`Uploaded ${index + 1}`}
                    />
                    <RemoveButton
                      onClick={() => handleRemovePhoto(index)}
                      aria-label="Remove Photo"
                    >
                      &times;
                    </RemoveButton>
                  </PhotoWrapper>
                ))}
              </Gallery>
            </GalleryContainer>

            {photos.length < 12 && (
              <AddMoreButton onClick={openFileDialog}>
                <ButtonIcon>
                  <i className="fas fa-plus" aria-hidden="true"></i>
                </ButtonIcon>
                Add More Images
              </AddMoreButton>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default PhotoUpload;
