// src/App.tsx

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import TemplateSelectionPage from './TemplateSelectionPage';
import StartingPage from './GetStartedPage';
import PurchasePage from './PurchasePage';
import OrderCompletePage from './OrderCompletePage';
import CustomModelsPage from './CustomModelsPage';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import '@fortawesome/fontawesome-free/css/all.min.css';

const App: React.FC = () => {
  // Initialize state from localStorage
  const [photos, setPhotos] = useState<File[]>(() => {
    const savedPhotos = localStorage.getItem('photos');
    if (savedPhotos) {
      try {
        const photosData = JSON.parse(savedPhotos); // array of { name, type, data }
        return photosData.map((photoData: { name: string; type: string; data: string }) => {
          const byteString = atob(photoData.data.split(',')[1]);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ia], { type: photoData.type });
          return new File([blob], photoData.name, { type: photoData.type });
        });
      } catch (error) {
        console.error('Error parsing photos from localStorage:', error);
        return [];
      }
    } else {
      return [];
    }
  });

  const [selectedTemplates, setSelectedTemplates] = useState<string[]>(() => {
    const savedTemplates = localStorage.getItem('selectedTemplates');
    return savedTemplates ? JSON.parse(savedTemplates) : [];
  });

  const [gender, setGender] = useState<string>(() => {
    const savedGender = localStorage.getItem('gender');
    return savedGender || '';
  });

  const [ethnicity, setEthnicity] = useState<string>(() => {
    const savedEthnicity = localStorage.getItem('ethnicity');
    return savedEthnicity || '';
  });

  // Save selectedTemplates to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedTemplates', JSON.stringify(selectedTemplates));
  }, [selectedTemplates]);

  useEffect(() => {
    if (gender) {
      localStorage.setItem('gender', gender);
    }
  }, [gender]);

  useEffect(() => {
    if (ethnicity) {
      localStorage.setItem('ethnicity', ethnicity);
    }
  }, [ethnicity]);

  // Save photos to localStorage whenever they change
  useEffect(() => {
    const savePhotosToLocalStorage = async () => {
      const readerPromises = photos.map((file) => {
        return new Promise<{ name: string; type: string; data: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              name: file.name,
              type: file.type,
              data: reader.result as string,
            });
          };
          reader.onerror = () => {
            reject(reader.error);
          };
          reader.readAsDataURL(file);
        });
      });

      try {
        const photosData = await Promise.all(readerPromises);
        localStorage.setItem('photos', JSON.stringify(photosData));
      } catch (error) {
        console.error('Error saving photos to localStorage:', error);
      }
    };

    if (photos.length > 0) {
      savePhotosToLocalStorage();
    } else {
      localStorage.removeItem('photos'); // Clear if no photos
    }
  }, [photos]);

  useEffect(() => {
    // This ensures the analytics initialization code runs
    if (analytics) {
      // Optionally, log a 'page_view' event
      logEvent(analytics, 'page_view');
    }
  }, []);

  const handlePhotoUpload = (newPhotos: File[]) => {
    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
  };

  const handleTemplateSelect = (templates: string[]) => {
    setSelectedTemplates(templates);
  };

  // Function to handle gender and ethnicity from StartingPage
  const handleGenderAndEthnicity = (selectedGender: string, selectedEthnicity: string) => {
    setGender(selectedGender);
    setEthnicity(selectedEthnicity);
  };

  return (
    <Router>
      <Routes>
        {/* Landing Page where users start */}
        <Route path="/" element={<LandingPage />} />

        {/* Template Selection Page is now the first step after LandingPage */}
        <Route
          path="/select-template"
          element={
            <TemplateSelectionPage
              // selectedTemplates={selectedTemplates}
              onSelectTemplates={handleTemplateSelect}
            />
          }
        />

        {/* Starting Page where users upload photos */}
        <Route
          path="/upload-photos"
          element={
            <StartingPage
              // photos={photos}
              // gender={gender}
              // ethnicity={ethnicity}
              onPhotoUpload={handlePhotoUpload}
              onGenderAndEthnicitySelect={handleGenderAndEthnicity}
            />
          }
        />

        {/* Purchase Page where users confirm the purchase */}
        <Route
          path="/purchase"
          element={
            <PurchasePage
              photos={photos}
              gender={gender}
              ethnicity={ethnicity}
              templates={selectedTemplates}
            />
          }
        />

        {/* Order Complete/Confirmation Page */}
        <Route path="/orders" element={<OrderCompletePage />} />

        <Route path="/custom-models" element={<CustomModelsPage />} />
      </Routes>
    </Router>
  );
};

export default App;
