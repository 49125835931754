// src/components/StartingPage.tsx

import React, { useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import PhotoUpload from './PhotoUpload';
import { useNavigate } from 'react-router-dom';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Select from 'react-select';
import { auth } from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import Navbar from './Navbar';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';

const PrivacyNotice = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #E8F0FE;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 36px;
`;

const IconContainer = styled.div`
  margin-right: 18px;
  color: #2563EB; // Blue-600 color
`;

const PrivacyText = styled.div`
  flex: 1;
  font-size: 0.95rem;
  color: #111827; // Gray-900 color
  line-height: 1.5;
`;

const PrivacyNoticeComponent: React.FC = () => (
  <PrivacyNotice>
    <IconContainer>
      <ShieldCheckIcon width={32} height={32} />
    </IconContainer>
    <PrivacyText>
      <strong>Your Privacy Matters:</strong> Your uploaded photos are permanently deleted once the order is complete. <strong>We never sell or save your data</strong>.
    </PrivacyText>
  </PrivacyNotice>
);

// =========================
// Global Styles
// =========================
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    background-color: #F9FAFB;
    color: #111827;
    height: 100%;
    overflow-y: auto;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

// =========================
// Keyframes for animations
// =========================
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// =========================
// Styled Components
// =========================

// Color Palette
const colors = {
  primary: '#2563EB', // Blue-600
  secondary: '#3B82F6', // Blue-500
  background: '#F9FAFB', // Gray-50
  surface: '#FFFFFF',
  error: '#DC2626', // Red-600
  onPrimary: '#FFFFFF',
  onBackground: '#111827', // Gray-900
};

// Navbar and related components are assumed to be handled in the Navbar component

const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 36px 72px;
  animation: ${fadeIn} 0.8s ease-in;
  background-color: ${colors.background};
  max-width: 1200px;
  margin: 0 auto;

  margin-top: -40px;

  @media (max-width: 1024px) {
    padding: 27px 54px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 18px 27px;
    margin-top: 0px;
  }

  @media (max-width: 480px) {
    padding: 13.5px 18px;
    margin-top: 0px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  max-width: 480px;
  margin-left: 45px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 36px;
  }
`;

const Card = styled.div`
  background-color: ${colors.surface};
  padding: 36px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin-bottom: 36px;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #E5E7EB;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 18px;
`;

const ProgressBarFiller = styled.div<{ percentage: number }>`
  height: 8px;
  width: ${(props) => props.percentage}%;
  background-color: ${colors.primary};
  transition: width 0.4s ease-in-out;
`;

const StepIndicator = styled.h5`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${colors.onBackground};
  margin-bottom: 9px;
  text-transform: uppercase;
  letter-spacing: 0.9px;
`;

const Title = styled.h1`
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 18px;
  color: ${colors.onBackground};
  
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
  
  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: ${colors.onBackground};
  margin-bottom: 24px;
  line-height: 1.6;
`;

const PhotoUploadWrapper = styled.div`
  flex: 1;
  max-width: 630px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 44px;
`;

const FormField = styled.div`
  margin-bottom: 22.5px;
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: ${colors.onBackground};
  font-weight: 600;
  margin-bottom: 7.2px;
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.span`
  color: ${colors.error};
  font-size: 0.81rem;
  margin-top: 4.5px;
`;

const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? colors.primary : '#D1D5DB',
    '&:hover': {
      borderColor: colors.primary,
    },
    boxShadow: state.isFocused ? `0 0 0 2px ${colors.primary}33` : 'none',
    borderRadius: '8px',
    padding: '2px 4px',
    minHeight: '48px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#E0F2FE' : '#fff',
    color: colors.onBackground,
    '&:active': {
      backgroundColor: '#BAE6FD',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#9CA3AF',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: colors.onBackground,
  }),
};

const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  background-color: ${colors.surface};
  border-top: 1px solid #E5E7EB;
  z-index: 10;

  // @media (max-width: 768px) {
  //   padding: 16px 54px;
  // }

  @media (max-width: 480px) {
    padding: 8px 16px;
  }
`;

const NavButton = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? colors.primary : 'transparent')};
  color: ${(props) => (props.primary ? colors.onPrimary : colors.primary)};
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: ${(props) => (props.primary ? 'none' : `2px solid ${colors.primary}`)};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.primary ? colors.secondary : `${colors.primary}1A`)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// =========================
// Interface for Props
// =========================
interface OptionType {
  value: string;
  label: string;
}

interface StartingPageProps {
  onPhotoUpload: (newPhotos: File[]) => void;
  onGenderAndEthnicitySelect: (gender: string, ethnicity: string) => void;
}

// =========================
// Starting Page Component
// =========================
const StartingPage: React.FC<StartingPageProps> = ({
  onPhotoUpload,
  onGenderAndEthnicitySelect,
}) => {
  const navigate = useNavigate();
  const [gender, setGender] = useState<OptionType | null>(null);
  const [ethnicity, setEthnicity] = useState<OptionType | null>(null);
  const [user] = useAuthState(auth);

  const [errors, setErrors] = useState<{ gender?: string; ethnicity?: string; photos?: string }>({});
  const [photosUploaded, setPhotosUploaded] = useState<File[]>([]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handlePhotoUpload = (newPhotos: File[]) => {
    setPhotosUploaded(newPhotos);
    onPhotoUpload(newPhotos);
    if (newPhotos.length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, photos: undefined }));
    }
  };

  const handleNext = () => {
    let valid = true;
    let newErrors: { gender?: string; ethnicity?: string; photos?: string } = {};

    if (!gender) {
      newErrors.gender = 'Please select your gender.';
      valid = false;
    }

    if (!ethnicity) {
      newErrors.ethnicity = 'Please select your ethnicity.';
      valid = false;
    }

    if (photosUploaded.length === 0) {
      newErrors.photos = 'Please upload at least one photo.';
      valid = false;
    }

    setErrors(newErrors);

    if (valid && gender && ethnicity) {
      onGenderAndEthnicitySelect(gender.value, ethnicity.value);

      if (analytics) {
        logEvent(analytics, 'step2_next_button_clicked', {});
      }

      navigate('/purchase');
    }
  };

  const handleBack = () => {
    navigate('/select-template');
  };

  const genderOptions: OptionType[] = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'non-binary', label: 'Non-Binary' },
    { value: 'prefer-not-to-say', label: 'Prefer not to say' },
  ];

  const ethnicityOptions: OptionType[] = [
    { value: 'asian', label: 'Asian' },
    { value: 'black', label: 'Black' },
    { value: 'hispanic', label: 'Hispanic' },
    { value: 'white', label: 'White' },
    { value: 'indian', label: 'Indian' },
    { value: 'mixed', label: 'Mixed' },
    { value: 'other', label: 'Other' },
    { value: 'prefer-not-to-say', label: 'Prefer not to say' },
  ];

  return (
    <>
      <GlobalStyle />
      <FullscreenContainer>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
        />

        <ContentContainer>
          <PhotoUploadWrapper>
            <Card>
              <PhotoUpload onPhotoUpload={handlePhotoUpload} />
              {errors.photos && <ErrorMessage>{errors.photos}</ErrorMessage>}
            </Card>
          </PhotoUploadWrapper>
          <TextContainer>
            <PrivacyNoticeComponent />
            <Card>
              <ProgressBarContainer>
                <ProgressBarFiller percentage={66} />
              </ProgressBarContainer>
              <StepIndicator>Step 2 of 3</StepIndicator>
              {/* <Title>Personalize Your AI Experience</Title> */}
              <FormSection>
                <FormField>
                  <Label htmlFor="gender">Select Gender</Label>
                  <Select
                    id="gender"
                    instanceId="gender-select"
                    value={gender}
                    onChange={(option) => setGender(option as OptionType)}
                    options={genderOptions}
                    placeholder="Select your gender"
                    styles={customSelectStyles}
                  />
                  {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
                </FormField>

                <FormField>
                  <Label htmlFor="ethnicity">Select Ethnicity</Label>
                  <Select
                    id="ethnicity"
                    instanceId="ethnicity-select"
                    value={ethnicity}
                    onChange={(option) => setEthnicity(option as OptionType)}
                    options={ethnicityOptions}
                    placeholder="Select your ethnicity"
                    styles={customSelectStyles}
                  />
                  {errors.ethnicity && <ErrorMessage>{errors.ethnicity}</ErrorMessage>}
                </FormField>
              </FormSection>
            </Card>
          </TextContainer>

          {/* <PhotoUploadWrapper>
            <Card>
              <PhotoUpload onPhotoUpload={handlePhotoUpload} />
              {errors.photos && <ErrorMessage>{errors.photos}</ErrorMessage>}
            </Card>
          </PhotoUploadWrapper> */}
        </ContentContainer>

        <BottomNav>
          <NavButton onClick={handleBack} aria-label="Go Back">
            Back
          </NavButton>
          <NavButton
            primary
            onClick={handleNext}
            aria-label="Go to Next Step"
            disabled={!gender || !ethnicity || photosUploaded.length === 0}
          >
            Next
          </NavButton>
        </BottomNav>
      </FullscreenContainer>
    </>
  );
};

export default StartingPage;
